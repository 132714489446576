// @ts-nocheck
import React from "react";
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import SingleProduct from "./SingleProduct";
import Cart from "./Cart";
import Products from "./Products";
import Home from "./Home";
import About from "./About";
import Contact from "./Contact";
import ErrorPage from "./ErrorPage";
import NavBar from "./components/Navbar";
import Checkout from "./checkout";
import Login from "./Login";
import Register from "./Register";
import AdminDashboard from "./Admin";
import Adminlogin from "./Adminlogin";
import AddProducts from "./AddProducts";
import AdminView from "./AdminView";
import UpdateProductForm from "./UpdateProductform";
import Viewcustomer from "./viewcustomer";
import UserDataFetcher from "./fetchusers";
import UserUpdate from "./Userupdate";
import Payment from "./Payment";
import Thanks from "./thanks";
import Paypal from "./Paypal";
import Success from "./Success";
import PaymentOptions from "./PaymentOptions";
import Orders from "./Orders";
import Email from "./Email";
import OurTeam from "./components/OurTeam";
import Profile from "./UserProfile";
import CheckoutFrim from "./checkingform";
import PrivateRoute from "./PrivateRoute";
const App = () => {
  return (
    <Router>
      <NavBar />
      <Routes>
        <Route path="/" element={<Home />} />
        <Route path="/about" element={<About />} />
        <Route path="/contact" element={<Contact />} />
        <Route path="/products" element={<Products />} />
        <Route
          path="/singleproduct/:id"
          element={
            <PrivateRoute>
              <SingleProduct />
            </PrivateRoute>
          }
        />
        <Route
          path="/cart"
          element={
            <PrivateRoute>
              <Cart />
            </PrivateRoute>
          }
        />
        <Route path="/checkout" element={<Checkout />} />
        <Route path="/login" element={<Login />} />
        <Route path="/register" element={<Register />} />
        <Route
          path="/admin"
          element={
            <PrivateRoute adminOnly={true}>
              <AdminDashboard />
            </PrivateRoute>
          }
        />
        <Route path="/adminlogin" element={<Adminlogin />} />
        <Route
          path="/addproducts"
          element={
            <PrivateRoute adminOnly={true}>
              <AddProducts />
            </PrivateRoute>
          }
        />
        <Route
          path="/adminview"
          element={
            <PrivateRoute adminOnly={true}>
              <AdminView />
            </PrivateRoute>
          }
        />
        <Route
          path="/update/:productId"
          element={
            <PrivateRoute adminOnly={true}>
              <UpdateProductForm />
            </PrivateRoute>
          }
        />
        <Route
          path="/users/:userId"
          element={
            <PrivateRoute>
              <UserUpdate />
            </PrivateRoute>
          }
        />
        <Route path="/viewcustomers" element={<Viewcustomer />} />
        <Route path="/fetchusers" element={<UserDataFetcher />} />
        <Route
          path="/paypal"
          element={
            <PrivateRoute>
              <Paypal />
            </PrivateRoute>
          }
        />
        <Route path="*" element={<ErrorPage />} />
        <Route
          path="/payment"
          element={
            <PrivateRoute>
              <Payment />
            </PrivateRoute>
          }
        />
        <Route
          path="/success"
          element={
            <PrivateRoute>
              <Success />
            </PrivateRoute>
          }
        />
        <Route
          path="/thanks"
          element={
            <PrivateRoute>
              <Thanks />
            </PrivateRoute>
          }
        />
        <Route
          path="/options"
          element={
            <PrivateRoute>
              <PaymentOptions />
            </PrivateRoute>
          }
        />
        <Route path="/email" element={<Email />} />
        <Route
          path="/orders"
          element={
            <PrivateRoute>
              <Orders />
            </PrivateRoute>
          }
        />

        <Route path="/team" element={<OurTeam />} />
        <Route
          path="/profile"
          element={
            <PrivateRoute>
              <Profile />
            </PrivateRoute>
          }
        />
        <Route path="/frim" element={<CheckoutFrim />} />
      </Routes>
    </Router>
  );
};

export default App;
