/* eslint-disable jsx-a11y/anchor-is-valid */
// @ts-nocheck
import React, { useState } from "react";
import "../src/styles/admin.css";
import OrderCount from "./Ordercount";
import Orders from "./Orders";
import AddProducts from "./AddProducts";
import AdminView from "./AdminView";
import Adminchat from "./AdminChat";
import UserDataFetcher from "./fetchusers";
import WelcomeAdmin from "./components/Dashboard";
import { NavLink } from "react-router-dom";
import { useAuth } from "./context/AuthContext";
function AdminDashboard() {
  const [selectedMenuItem, setSelectedMenuItem] = useState(null);
  const { signOut } = useAuth();
  const handleLoginButtonClick = () => {
    signOut();
  };
  const renderContent = () => {
    switch (selectedMenuItem) {
      //Add Product case
      case "addproducts":
        return (
          // Add The Product Component
          <AddProducts />
        );
      //DashBoard case
      case "DashBoard":
        return (
          // DashBoard Component
          <div style={{ marginLeft: "-200px", marginTop: "-300px" }}>
            <WelcomeAdmin />
          </div>
        );
      //orders case
      case "orders":
        return (
          //Order Component
          <Orders />
        );
      case "view":
        return <AdminView />;
      case "Adminchat":
        return (
          <div style={{ marginLeft: "-300px", marginTop: "-50px" }}>
            <Adminchat />
          </div>
        );
      case "usertable":
        return <UserDataFetcher />;
      default:
        return (
          <div style={{ marginLeft: "-200px", marginTop: "-300px" }}>
            <WelcomeAdmin />
          </div>
        );
    }
  };

  return (
    <div className="container-fluid">
    <br />
    <div className="row">
      <div className="col-md-3">
        <div id="sidebar-wrapper" style={{ background: "black" }}>
          <div className="list-group list-group-flush">
            <a
              href="#"
              className="list-group-item list-group-item-action"
              onClick={() => setSelectedMenuItem("DashBoard")}
              style={{
                backgroundColor: selectedMenuItem === "DashBoard" ? "red" : "white",
                color: selectedMenuItem === "DashBoard" ? "white" : "black",
                cursor: "pointer",
              }}
              onMouseEnter={(e) => {
                e.target.style.backgroundColor = "red";
                e.target.style.color = "white";
              }}
              onMouseLeave={(e) => {
                if (selectedMenuItem !== "DashBoard") {
                  e.target.style.backgroundColor = "white";
                  e.target.style.color = "black";
                }
              }}
            >
              DashBoard
            </a>
            <a
              href="#"
              className="list-group-item list-group-item-action"
              onClick={() => setSelectedMenuItem("addproducts")}
              style={{
                backgroundColor: selectedMenuItem === "addproducts" ? "red" : "white",
                color: selectedMenuItem === "addproducts" ? "white" : "black",
                cursor: "pointer",
              }}
              onMouseEnter={(e) => {
                e.target.style.backgroundColor = "red";
                e.target.style.color = "white";
              }}
              onMouseLeave={(e) => {
                if (selectedMenuItem !== "addproducts") {
                  e.target.style.backgroundColor = "white";
                  e.target.style.color = "black";
                }
              }}
            >
              Add New Product
            </a>
            <a
              href="#"
              className="list-group-item list-group-item-action"
              onClick={() => setSelectedMenuItem("orders")}
              style={{
                backgroundColor: selectedMenuItem === "orders" ? "red" : "white",
                color: selectedMenuItem === "orders" ? "white" : "black",
                cursor: "pointer",
              }}
              onMouseEnter={(e) => {
                e.target.style.backgroundColor = "red";
                e.target.style.color = "white";
              }}
              onMouseLeave={(e) => {
                if (selectedMenuItem !== "orders") {
                  e.target.style.backgroundColor = "white";
                  e.target.style.color = "black";
                }
              }}
            >
              <OrderCount />
            </a>
            <a
              href="#"
              className="list-group-item list-group-item-action"
              onClick={() => setSelectedMenuItem("view")}
              style={{
                backgroundColor: selectedMenuItem === "view" ? "red" : "white",
                color: selectedMenuItem === "view" ? "white" : "black",
                cursor: "pointer",
              }}
              onMouseEnter={(e) => {
                e.target.style.backgroundColor = "red";
                e.target.style.color = "white";
              }}
              onMouseLeave={(e) => {
                if (selectedMenuItem !== "view") {
                  e.target.style.backgroundColor = "white";
                  e.target.style.color = "black";
                }
              }}
            >
              View Products
            </a>
            <a
              href="#"
              className="list-group-item list-group-item-action"
              onClick={() => setSelectedMenuItem("usertable")}
              style={{
                backgroundColor: selectedMenuItem === "usertable" ? "red" : "white",
                color: selectedMenuItem === "usertable" ? "white" : "black",
                cursor: "pointer",
              }}
              onMouseEnter={(e) => {
                e.target.style.backgroundColor = "red";
                e.target.style.color = "white";
              }}
              onMouseLeave={(e) => {
                if (selectedMenuItem !== "usertable") {
                  e.target.style.backgroundColor = "white";
                  e.target.style.color = "black";
                }
              }}
            >
              Registered Users
            </a>
            <a className="list-group-item list-group-item-action">
              <button className="btn btn-dark" onClick={handleLoginButtonClick}>
                Logout
              </button>
            </a>
          </div>
        </div>
      </div>
      <div className="col-md-9">{renderContent()}</div>
    </div>
  </div>
  );
}

export default AdminDashboard;
