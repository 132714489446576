// @ts-nocheck
import React from "react";
import { Link } from "react-router-dom";
import { Container, Row, Col } from "react-bootstrap";
import "../styles/Header.css";

const Header = ({ mydata }) => {
  const { name } = mydata;
  return (
    <>
      <header className="header container text-center">
        <Container>
          <Row>
            <Col md={6} className="header-content">
              <h1 style={{ color: "gray" }}>Welcome to wowDisplay</h1>
              <p>Discover amazing Banners and Posters!</p>
              <Link to="/products" className="btn btn-dark btn-lg">
                Explore Now
              </Link>
            </Col>
            <Col md={6} className="header-image">
              <img
                src="/images/image8.jpg"
                alt="Header Image"
                className="img-fluid"
              />
            </Col>
          </Row>
        </Container>
      </header>
      <br />
      <div className="container">
        <div className="row">
          <div className="col-lg-6 col-md-6 col-sm-12">
            <img
              src="/images/image4.jpg"
              alt="About Us Image"
              className="img-fluid"
            />
          </div>
          <div className="col-lg-6 col-md-6 col-sm-12">
            <h2>About Us</h2>
            <p>
            Welcome to WowBanners! We are your go-to destination for high-quality, customized banners and posters, tailored to your specific needs. Whether you're planning a corporate event, promoting your business, or adding a personal touch to a special occasion, we deliver stunning, personalized designs straight to your doorstep. Our goal is to make the process seamless and enjoyable, offering a variety of materials and sizes to ensure your banners and posters are perfect for any occasion. With WowBanners, you can count on premium quality and exceptional service every time!
            </p>
          <p>At WowBanners, we specialize in delivering high-quality, custom-designed banners and posters straight to your doorstep. Whether you’re a business looking to make a bold statement, a school planning an event, or an individual in need of personalized decor, our platform makes it simple to bring your vision to life. With a wide range of sizes, materials, and designs to choose from, we ensure that each product is tailored to meet your unique needs. Our commitment to quality craftsmanship, fast delivery, and outstanding customer service has made us a trusted destination for all your banner and poster needs. At WowBanners, we turn your ideas into stunning visual displays, helping you make a lasting impression in any space.</p>
           
          </div>
        </div>
      </div>
    </>
  );
};

export default Header;
