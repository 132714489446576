// @ts-nocheck
import React from 'react'
import "../styles/maps2.css";
const Form = () => {
  return (
    <>
      <div className="container text-center">
        <form action="https://formspree.io/f/xvojkjya" method="POST" className="contact-form">
          <h2 className="mb-4">Contact Us</h2>
          <div className="mb-3">
            <label
              for="email" className="form-label">Your email:</label>
            <input type="email" id="email" name="email" className="form-control" required />
          </div>
          <div className="mb-3">
            <label

              for="message" className="form-label">Your message:</label>
            <textarea id="message" name="message"

              rows="4" className="form-control" required></textarea>
          </div>
          <button type="submit" className="btn btn-primary">Send</button>
        </form>
      </div>
<br/>



    </>
  )
}
export default Form;
