import React from "react";
import { ProgressBar, Card, Button } from "react-bootstrap";
import { FaStar } from "react-icons/fa";

const ReviewSnapshot = () => {
  return (
    <div className="container mt-5 ">
      {/* Rating Header */}
      <div className="d-flex align-items-center mb-4 gap-4">
        <h4 className="mr-5 mb-0">Review Snapshot</h4>
        <div className="d-flex align-items-center gap-2">
          <FaStar color="#FFA41C" />
          <FaStar color="#FFA41C" />
          <FaStar color="#FFA41C" />
          <FaStar color="#FFA41C" />
          <FaStar color="#FFA41C" />
          <span className="ml-2" style={{ fontSize: "1.5rem" }}>
            4.8
          </span>
          <span className="ml-2">(537)</span>
        </div>
      </div>

      {/* Write Review Button */}
      <Button variant="outline-secondary" className="mb-3">
        Write a Review
      </Button>

      {/* Recommendation */}
      <h1 className="display-4">86%</h1>
      <p>of respondents would recommend this to a friend</p>

      {/* Star Distribution */}
      <div>
        <div className="d-flex justify-content-between align-items-center">
          <span>5 Stars</span>
          <ProgressBar now={90} className="w-75" />
          <span>476</span>
        </div>
        <div className="d-flex justify-content-between align-items-center mt-2">
          <span>4 Stars</span>
          <ProgressBar now={5} className="w-75" />
          <span>28</span>
        </div>
        <div className="d-flex justify-content-between align-items-center mt-2">
          <span>3 Stars</span>
          <ProgressBar now={2} className="w-75" />
          <span>10</span>
        </div>
        <div className="d-flex justify-content-between align-items-center mt-2">
          <span>2 Stars</span>
          <ProgressBar now={1} className="w-75" />
          <div>08</div>
        </div>
        <div className="d-flex justify-content-between align-items-center mt-2">
          <span>1 Star</span>
          <ProgressBar now={2} className="w-75" />
          <div>15</div>
        </div>
      </div>

      {/* Product Ratings */}
      <Card className="mt-4 p-4 text-black">
        <h5>Product Ratings</h5>
        <div className="d-flex justify-content-between">
          <div>Ease of Design</div>
          <div className="d-flex align-items-center gap-2">
            <FaStar color="#FFA41C" />
            <FaStar color="#FFA41C" />
            <FaStar color="#FFA41C" />
            <FaStar color="#FFA41C" />
            <FaStar color="#FFA41C" />
            <span className="ml-3">4.5</span>
          </div>
        </div>
        <div className="d-flex justify-content-between mt-2">
          <div>Product Quality</div>
          <div className="d-flex align-items-center gap-2">
            <FaStar color="#FFA41C" />
            <FaStar color="#FFA41C" />
            <FaStar color="#FFA41C" />
            <FaStar color="#FFA41C" />
            <FaStar color="#FFA41C" className="mr-3" />
            <span className="ml-3">4.6</span>
          </div>
        </div>
        <div className="d-flex justify-content-between mt-2">
          <div>Product Value</div>
          <div className="d-flex align-items-center gap-2">
            <FaStar color="#FFA41C" />
            <FaStar color="#FFA41C" />
            <FaStar color="#FFA41C" />
            <FaStar color="#FFA41C" />
            <FaStar color="#FFA41C" />
            <span className="ml-3">4.5</span>
          </div>
        </div>
      </Card>
    </div>
  );
};

export default ReviewSnapshot;
