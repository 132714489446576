// // @ts-nocheck
// import React from "react";
// import Filtersection from "./components/filtersection";
// import Productlist from "./components/productlist";
// import Footer from "./components/newHome/Footer/Footer";

// const Products = () => {
//   return (
//     <div style={{ background: "#001F3F" }}>
//       <br />
//       <Filtersection />
//       <Productlist />
//       <br />
//       <Footer />
//     </div>
//   );
// };

// export default Products;
import React from "react";
import Filtersection from "./components/filtersection";
import Productlist from "./components/productlist";
import Footer from "./components/newHome/Footer/Footer";
import Product from "./components/Product";
import { useFilterContext } from "./context/FilterContext";

const Products = () => {
  const { Filter_Products, Grid_View } = useFilterContext();

  return (
    <div style={{ background: "gray", minHeight: "100vh", }}>
      <div className="container py-5">
        <div className="row">
          {/* Sidebar for filter section */}
          <div className="col-md-3">
            <Filtersection />
          </div>

          {/* Main content for product list */}
          <div className="col-md-9">
            <div className="row row-cols-1 row-cols-sm-1  row-cols-lg-2 row-cols-xl-3 g-4 m-auto">
              {Filter_Products.map((curElem) => (
                <div key={curElem.id}>
                  <Product {...curElem} />
                </div>
              ))}
            </div>          </div>
        </div>
      </div>
      <Footer />
    </div>
  );
};

export default Products;
