import React, { useState } from "react";
import { AiOutlineLeft, AiOutlineRight } from "react-icons/ai";

const MobileSlider = ({ slides }) => {
  const [currentIndex, setCurrentIndex] = useState(0);

  const nextSlide = () => {
    setCurrentIndex((prevIndex) =>
      prevIndex === slides.length - 1 ? 0 : prevIndex + 1
    );
  };

  const prevSlide = () => {
    setCurrentIndex((prevIndex) =>
      prevIndex === 0 ? slides.length - 1 : prevIndex - 1
    );
  };

  return (
    <div className="position-relative w-100 h-100 d-block">
      <div
        className="position-relative w-100"
        style={{ height: "18rem", overflow: "hidden" }}
      >
        {slides.map((slide, index) => (
          <div
            key={index}
            className={`position-absolute top-0 h-100 transition-transform duration-500 ease-in-out ${
              index === currentIndex ? "start-0 opacity-100" : "d-none"
            }`}
            style={{
              width: "100%",
            }}
          >
            <img
              src={slide.image}
              alt={slide.title}
              className="w-100 h-100 object-cover rounded"
            />
            <div className="position-absolute bottom-2 start-2 bg-white bg-opacity-90 px-2 py-1 rounded shadow-lg d-flex align-items-center"></div>
          </div>
        ))}
      </div>
      <button
        onClick={prevSlide}
        className="position-absolute top-50 start-0 translate-middle-y bg-white p-1 rounded-circle shadow-lg"
      >
        <AiOutlineLeft size={20} className="text-dark" />
      </button>
      <button
        onClick={nextSlide}
        className="position-absolute top-50 end-0 translate-middle-y bg-white p-1 rounded-circle shadow-lg"
      >
        <AiOutlineRight size={20} className="text-dark" />
      </button>
    </div>
  );
};

export default MobileSlider;
