import React, { useState, useEffect } from "react";
import axios from "axios";
import { useNavigate, useParams } from "react-router-dom";

function UpdateProductForm() {
  const navigate = useNavigate();
  const { productId } = useParams();

  const [product, setProduct] = useState({
    name: "",
    description: "",
    price: 0,
    category: "",
    company: "",
    stockAvailability: 0,
    starRating: 0,
    featured: false,
  });

  const [updatedProduct, setUpdatedProduct] = useState({ ...product });

  useEffect(() => {
    axios
      .get(`https://test-node-flame.vercel.app/api/products/${productId}`)
      .then((response) => {
        setProduct(response.data);
        setUpdatedProduct(response.data);
      })
      .catch((error) => {
        console.error("Error fetching product:", error);
      });
  }, [productId]);

  const handleUpdate = () => {
    axios
      .put(
        `https://test-node-flame.vercel.app/api/products/${productId}`,
        updatedProduct
      )
      .then(() => {
        console.log("Product updated successfully");
        alert("Product updated successfully!");
        navigate("/admin");
      })
      .catch((error) => {
        console.error("Error updating product:", error);
      });
  };

  const handleInputChange = (event) => {
    const { name, value, type, checked } = event.target;
    setUpdatedProduct({
      ...updatedProduct,
      [name]: type === "checkbox" ? checked : value,
    });
  };

  return (
    <div
      style={{
        maxWidth: "1000px",
        margin: "0 auto",
        padding: "20px",
        border: "1px solid #ddd",
        borderRadius: "8px",
        backgroundColor: "#f9f9f9",
        boxShadow: "0 4px 8px rgba(0, 0, 0, 0.1)",
      }}
    >
      <h2
        style={{
          textAlign: "center",
          color: "#333",
          fontFamily: "Arial, sans-serif",
          marginBottom: "20px",
        }}
      >
        Edit Product
      </h2>
      <form>
        <div className="form-group" style={{ marginBottom: "15px" }}>
          <label htmlFor="name" style={{ fontWeight: "bold" }}>
            Product Name
          </label>
          <input
            type="text"
            id="name"
            name="name"
            value={updatedProduct.name || product.name}
            onChange={handleInputChange}
            style={{
              width: "100%",
              padding: "10px",
              border: "1px solid #ccc",
              borderRadius: "4px",
              marginTop: "5px",
            }}
          />
        </div>
        <div className="form-group" style={{ marginBottom: "15px" }}>
          <label htmlFor="description" style={{ fontWeight: "bold" }}>
            Product Description
          </label>
          <textarea
            id="description"
            name="description"
            value={updatedProduct.description || product.description}
            onChange={handleInputChange}
            style={{
              width: "100%",
              padding: "10px",
              border: "1px solid #ccc",
              borderRadius: "4px",
              marginTop: "5px",
              minHeight: "100px",
            }}
          />
        </div>
        <div className="form-group" style={{ marginBottom: "15px" }}>
          <label htmlFor="price" style={{ fontWeight: "bold" }}>
            Product Price
          </label>
          <input
            type="number"
            id="price"
            name="price"
            value={updatedProduct.price || product.price}
            onChange={handleInputChange}
            style={{
              width: "100%",
              padding: "10px",
              border: "1px solid #ccc",
              borderRadius: "4px",
              marginTop: "5px",
            }}
          />
        </div>
        <div className="form-group" style={{ marginBottom: "15px" }}>
          <label htmlFor="category" style={{ fontWeight: "bold" }}>
            Product Category
          </label>
          <input
            type="text"
            id="category"
            name="category"
            value={updatedProduct.category || product.category}
            onChange={handleInputChange}
            style={{
              width: "100%",
              padding: "10px",
              border: "1px solid #ccc",
              borderRadius: "4px",
              marginTop: "5px",
            }}
          />
        </div>
        <div className="form-group" style={{ marginBottom: "15px" }}>
          <label htmlFor="company" style={{ fontWeight: "bold" }}>
            Product Company
          </label>
          <input
            type="text"
            id="company"
            name="company"
            value={updatedProduct.company || product.company}
            onChange={handleInputChange}
            style={{
              width: "100%",
              padding: "10px",
              border: "1px solid #ccc",
              borderRadius: "4px",
              marginTop: "5px",
            }}
          />
        </div>
        <div className="form-group" style={{ marginBottom: "15px" }}>
          <label htmlFor="starRating" style={{ fontWeight: "bold" }}>
            Product Star Rating
          </label>
          <input
            type="number"
            id="starRating"
            name="starRating"
            value={updatedProduct.starRating || ""}
            onChange={handleInputChange}
            className="form-control"
            style={{
              width: "100%",
              padding: "10px",
              border: "1px solid #ccc",
              borderRadius: "4px",
              marginTop: "5px",
            }}
          />
        </div>
        <div className="form-group" style={{ marginBottom: "15px" }}>
          <label htmlFor="stockAvailability" style={{ fontWeight: "bold" }}>
            Product Stock Availability
          </label>
          <input
            type="number"
            id="stockAvailability"
            name="stockAvailability"
            value={updatedProduct.stockAvailability || ""}
            onChange={handleInputChange}
            required
            className="form-control"
            style={{
              width: "100%",
              padding: "10px",
              border: "1px solid #ccc",
              borderRadius: "4px",
              marginTop: "5px",
            }}
          />
        </div>
        <div className="form-group" style={{ marginBottom: "15px" }}>
          <label style={{ fontWeight: "bold" }}>
            <input
              type="checkbox"
              name="featured"
              checked={updatedProduct.featured}
              onChange={handleInputChange}
              style={{ marginRight: "10px" }}
            />
            Featured
          </label>
        </div>

        <button
          type="button"
          onClick={handleUpdate}
          style={{
            width: "100%",
            padding: "10px",
            backgroundColor: "#16757D",
            color: "#fff",
            border: "none",
            borderRadius: "4px",
            fontWeight: "bold",
            cursor: "pointer",
          }}
        >
          Update
        </button>
      </form>
    </div>
  );
}

export default UpdateProductForm;
