// @ts-nocheck
/* eslint-disable jsx-a11y/img-redundant-alt */
export const formatTime = (seconds) => {
  const hours = Math.floor(seconds / 3600);
  const minutes = Math.floor((seconds % 3600) / 60);
  const remainingSeconds = seconds % 60;

  const formattedTime = `${padZero(hours)}:${padZero(minutes)}:${padZero(
    remainingSeconds
  )}`;
  return formattedTime;
};

const padZero = (num) => {
  return num.toString().padStart(2, "0");
};

// Define other utility functions here if needed
export const ProductImage = ({ images, style, props }) => {
  const baseUrl = "https://test-node-flame.vercel.app/api/images/"; // Change this to your actual base URL

  const imageUrl = isValidUrl(images[0]) ? images[0] : `${baseUrl}${images[0]}`;

  return (
    // @ts-ignore
    <img
      src={imageUrl}
      {...props}
      alt={"image"}
      style={style ? style : { maxWidth: "100px", maxHeight: "100px" }}
    />
  );
};

// Utility function to check if a string is a valid URL
function isValidUrl(string) {
  try {
    new URL(string);
    return true;
  } catch (_) {
    return false;
  }
}
export const makeValidUrls = (images, baseUrl) => {
  return images.map((image) => {
    if (isValidUrl(image)) {
      return image;
    } else {
      return `${baseUrl}${image}`;
    }
  });
};
