import React from "react";
import { FaFacebookF, FaInstagram, FaTwitter } from "react-icons/fa";

const Footer = () => {
  return (
    <footer
      className="text-white py-5 border-1 border-top"
      style={{ backgroundColor: "#001f3f" }}
    >
      <div className="container">
        <div className="row">
          {/* Left Section - Branding and Social Links */}
          <div className="col-md-3 mb-4">
            <div className="mb-4">
              <div className="h5 font-weight-bold">wowDisplay</div>
            </div>
            <h4 className="h6 font-weight-bold my-4">Follow Us</h4>
            <div className="d-flex mb-4">
              <a href="#" aria-label="Facebook" className="text-white me-3">
                <FaFacebookF size={24} />
              </a>
              <a href="#" aria-label="Instagram" className="text-white me-3">
                <FaInstagram size={24} />
              </a>
              <a href="#" aria-label="Twitter" className="text-white me-3">
                <FaTwitter size={24} />
              </a>
            </div>
            <p className="small">
              wowDisplay is dedicated to offering high-quality, customizable
              display solutions to help businesses, events, and individuals
              stand out. From banners to posters, we provide innovative designs
              tailored to your needs.
            </p>
          </div>

          {/* Right Section - Links */}
          <div className="col-md-9 d-flex justify-content-around">
            <div className="mb-4">
              <h3 className="h6 font-weight-bold">Products</h3>
              <ul className="list-unstyled">
                <li>
                  <a href="/adminlogin" className="text-white d-block my-2">
                    Custom Banners
                  </a>
                </li>
                <li>
                  <a href="#" className="text-white d-block my-2">
                    Custom Posters
                  </a>
                </li>
                <li>
                  <a href="#" className="text-white d-block my-2">
                    Event Displays
                  </a>
                </li>
                <li>
                  <a href="#" className="text-white d-block my-2">
                    Branding Solutions
                  </a>
                </li>
                <li>
                  <a href="#" className="text-white d-block my-2">
                    Digital Signage
                  </a>
                </li>
              </ul>
            </div>

            <div className="mb-4">
              <h3 className="h6 font-weight-bold">Use Cases</h3>
              <ul className="list-unstyled">
                <li>
                  <a href="#" className="text-white d-block my-2">
                    Businesses
                  </a>
                </li>
                <li>
                  <a href="#" className="text-white d-block my-2">
                    Event Organizers
                  </a>
                </li>
                <li>
                  <a href="#" className="text-white d-block my-2">
                    Retail Stores
                  </a>
                </li>
                <li>
                  <a href="#" className="text-white d-block my-2">
                    Schools & Colleges
                  </a>
                </li>
              </ul>
            </div>

            <div className="mb-4">
              <h3 className="h6 font-weight-bold">Resources</h3>
              <ul className="list-unstyled">
                <li>
                  <a href="#" className="text-white d-block my-2">
                    Design Inspiration
                  </a>
                </li>
                <li>
                  <a href="#" className="text-white d-block my-2">
                    Blog
                  </a>
                </li>
                <li>
                  <a href="#" className="text-white d-block my-2">
                    How-to Guides
                  </a>
                </li>
                <li>
                  <a href="#" className="text-white d-block my-2">
                    Support
                  </a>
                </li>
                <li>
                  <a href="#" className="text-white d-block my-2">
                    FAQs
                  </a>
                </li>
              </ul>
            </div>

            <div className="mb-4">
              <h3 className="h6 font-weight-bold">Company</h3>
              <ul className="list-unstyled">
                <li>
                  <a href="#" className="text-white d-block my-2">
                    About Us
                  </a>
                </li>
                <li>
                  <a href="#" className="text-white d-block my-2">
                    Careers
                  </a>
                </li>
                <li>
                  <a href="#" className="text-white d-block my-2">
                    Teams
                  </a>
                </li>
                <li>
                  <a href="#" className="text-white d-block my-2">
                    Contact Us
                  </a>
                </li>
              </ul>
            </div>
          </div>
        </div>

        <div className="text-center small text-muted mt-5">
          <hr className="my-4" />© 2024 wowDisplay. All Rights Reserved
        </div>
      </div>
    </footer>
  );
};

export default Footer;
