// // @ts-nocheck
// import React, { useState } from "react";
// import "./App.css";
// import "../src/styles/AddProduct.css";
// import { useNavigate } from "react-router-dom";
// const AddProducts = () => {
//   const navigate = useNavigate();
//   const [product, setProduct] = useState({
//     name: "",
//     description: "",
//     category: "",
//     price: "",
//     image: "",
//     company: "",
//     starRating: "",
//     stockAvailability: "",
//     colors: [],
//     featured: false,
//   });

//   const handleInputChange = (e) => {
//     const { name, value, type, checked, files } = e.target;
//     let newValue;

//     if (type === "checkbox") {
//       newValue = checked;
//     } else if (type === "file") {
//       newValue = files[0]; // Select the first file from the input
//     } else {
//       newValue = value;
//     }

//     setProduct({ ...product, [name]: newValue });
//   };

//   const handleColorChange = (e) => {
//     const { name, checked } = e.target;
//     const updatedColors = [...product.colors];

//     if (checked) {
//       updatedColors.push(name);
//     } else {
//       const index = updatedColors.indexOf(name);
//       if (index !== -1) {
//         updatedColors.splice(index, 1);
//       }
//     }
//     setProduct({ ...product, colors: updatedColors });
//   };

//   const handleSubmit = async (e) => {
//     e.preventDefault();

//     // Create a FormData object
//     const formData = new FormData();

//     // Append each product attribute to the FormData
//     formData.append("name", product.name);
//     formData.append("description", product.description);
//     formData.append("category", product.category);
//     formData.append("price", product.price);
//     formData.append("company", product.company);
//     formData.append("starRating", product.starRating);
//     formData.append("stockAvailability", product.stockAvailability);
//     formData.append("colors", JSON.stringify(product.colors));
//     formData.append("featured", product.featured);

//     // Append the image file
//     if (product.image) {
//       formData.append("image", product.image);
//     }

//     try {
//       const response = await fetch(
//         "https://test-node-flame.vercel.app/api/products",
//         {
//           method: "POST",
//           body: formData, // Send FormData instead of JSON
//         }
//       );

//       if (response.status === 201) {
//         alert("Product added successfully!");
//         navigate("/admin");
//         setProduct({
//           name: "",
//           description: "",
//           category: "",
//           price: "",
//           image: null, // Reset image to null
//           company: "",
//           starRating: "",
//           stockAvailability: "",
//           colors: [],
//           featured: false,
//         });
//       } else {
//         alert("Failed to add the product.");
//       }
//     } catch (error) {
//       console.error("Error while adding the product:", error);
//       alert("An error occurred while adding the product.");
//     }
//   };

//   return (
//     <div style={{ backgroundColor: "lightgray" }}>
//       <div style={styles.container}>
//         <form onSubmit={handleSubmit} style={styles.form}>
//           <div style={styles.formRow}>
//             <div style={styles.formGroup}>
//               <label htmlFor="name" style={styles.label}>
//                 Product Name
//               </label>
//               <input
//                 type="text"
//                 id="name"
//                 name="name"
//                 value={product.name}
//                 onChange={handleInputChange}
//                 required
//                 style={styles.input}
//               />
//             </div>
//             <div style={styles.formGroup}>
//               <label htmlFor="description" style={styles.label}>
//                 Product Description
//               </label>
//               <textarea
//                 id="description"
//                 name="description"
//                 value={product.description}
//                 onChange={handleInputChange}
//                 required
//                 style={{ ...styles.input, height: "100px" }}
//               />
//             </div>
//           </div>

//           <div style={styles.formRow}>
//             <div style={styles.formGroup}>
//               <label htmlFor="category" style={styles.label}>
//                 Product Category
//               </label>
//               <input
//                 type="text"
//                 id="category"
//                 name="category"
//                 value={product.category}
//                 onChange={handleInputChange}
//                 required
//                 style={styles.input}
//               />
//             </div>
//             <div style={styles.formGroup}>
//               <label htmlFor="price" style={styles.label}>
//                 Product Price
//               </label>
//               <input
//                 type="number"
//                 id="price"
//                 name="price"
//                 value={product.price}
//                 onChange={handleInputChange}
//                 required
//                 style={styles.input}
//               />
//             </div>
//           </div>

//           <div style={styles.formRow}>
//             <div style={styles.formGroup}>
//               <label htmlFor="company" style={styles.label}>
//                 Type
//               </label>
//               <input
//                 type="text"
//                 id="company"
//                 name="company"
//                 value={product.company}
//                 onChange={handleInputChange}
//                 required
//                 style={styles.input}
//               />
//             </div>
//             <div style={styles.formGroup}>
//               <label htmlFor="starRating" style={styles.label}>
//                 Product Star Rating
//               </label>
//               <input
//                 type="number"
//                 id="starRating"
//                 name="starRating"
//                 value={product.starRating}
//                 onChange={handleInputChange}
//                 style={styles.input}
//               />
//             </div>
//           </div>

//           <div style={styles.formRow}>
//             <div style={styles.formGroup}>
//               <label htmlFor="stockAvailability" style={styles.label}>
//                 Stock Availability
//               </label>
//               <input
//                 type="number"
//                 id="stockAvailability"
//                 name="stockAvailability"
//                 value={product.stockAvailability}
//                 onChange={handleInputChange}
//                 required
//                 style={styles.input}
//               />
//             </div>
//           </div>

//           <div style={styles.formGroup}>
//             <label style={styles.checkboxLabel}>
//               <input
//                 type="checkbox"
//                 name="featured"
//                 checked={product.featured}
//                 onChange={handleInputChange}
//                 style={styles.checkbox}
//               />
//               Featured
//             </label>
//           </div>

//           <div style={styles.formGroup}>
//             <label htmlFor="image" style={styles.label}>
//               Upload Product Image
//             </label>
//             <input
//               type="file"
//               id="image"
//               name="image"
//               accept="image/*"
//               onChange={handleInputChange}
//               style={styles.input}
//             />
//           </div>

//           <button type="submit" style={styles.button}>
//             Add Product
//           </button>
//         </form>
//       </div>
//     </div>
//   );
// };
// const styles = {
//   pageContainer: {
//     height: "100vh",
//     width: "100vw",
//     display: "flex",
//     justifyContent: "center",
//     alignItems: "center",
//     backgroundColor: "lightblue !important", // Light background color for the full page
//   },
//   formContainer: {
//     width: "100%",
//     maxWidth: "700px",
//     backgroundColor: "black", // Form background color
//     padding: "30px",
//     borderRadius: "10px",
//     boxShadow: "0 8px 16px rgba(0, 0, 0, 0.1)",
//     margin: "0 20px",
//   },
//   form: {
//     display: "flex",
//     flexDirection: "column",
//   },
//   formRow: {
//     display: "flex",
//     justifyContent: "space-between",
//     marginBottom: "15px",
//   },
//   formGroup: {
//     flex: "1",
//     margin: "0 10px",
//   },
//   label: {
//     fontSize: "14px",
//     fontWeight: "bold",
//     marginBottom: "5px",
//     display: "block",
//   },
//   input: {
//     width: "100%",
//     padding: "10px",
//     borderRadius: "5px",
//     border: "1px solid #ccc",
//     fontSize: "16px",
//   },
//   checkboxLabel: {
//     display: "flex",
//     alignItems: "center",
//     fontSize: "14px",
//   },
//   checkbox: {
//     marginRight: "10px",
//   },
//   button: {
//     padding: "12px",
//     borderRadius: "5px",
//     backgroundColor: "#333",
//     color: "#fff",
//     border: "none",
//     fontSize: "16px",
//     cursor: "pointer",
//     alignSelf: "flex-start",
//     marginTop: "20px",
//   },
// };

// export default AddProducts;
// @ts-nocheck
import React, { useState } from "react";
import "./App.css";
import "../src/styles/AddProduct.css";
import { useNavigate } from "react-router-dom";

const AddProducts = () => {
  const navigate = useNavigate();
  const [product, setProduct] = useState({
    name: "",
    description: "",
    category: "",
    price: "",
    images: "",
    company: "",
    starRating: "",
    stockAvailability: "",
    colors: [],
    featured: false,
  });

  // Helper function to convert file to base64
  const convertToBase64 = (file) => {
    return new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = () => resolve(reader.result);
      reader.onerror = (error) => reject(error);
    });
  };

  // Handle form input changes
  const handleInputChange = async (e) => {
    const { name, value, type, checked, files } = e.target;
    let newValue;

    if (type === "checkbox") {
      newValue = checked;
    } else if (type === "file") {
      const file = files[0];
      newValue = await convertToBase64(file); // Convert file to base64
    } else {
      newValue = value;
    }

    setProduct({ ...product, [name]: newValue });
  };

  const handleColorChange = (e) => {
    const { name, checked } = e.target;
    const updatedColors = [...product.colors];

    if (checked) {
      updatedColors.push(name);
    } else {
      const index = updatedColors.indexOf(name);
      if (index !== -1) {
        updatedColors.splice(index, 1);
      }
    }
    setProduct({ ...product, colors: updatedColors });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    try {
      const response = await fetch(
        "https://test-node-flame.vercel.app/api/products",
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify(product), // Send as JSON payload
        }
      );

      if (response.status === 201) {
        alert("Product added successfully!");
        navigate("/admin");
        setProduct({
          name: "",
          description: "",
          category: "",
          price: "",
          images: "", // Reset image
          company: "",
          starRating: "",
          stockAvailability: "",
          colors: [],
          featured: false,
        });
      } else {
        alert("Failed to add the product.");
      }
    } catch (error) {
      console.error("Error while adding the product:", error);
      alert("An error occurred while adding the product.");
    }
  };

  return (
    <div style={{ backgroundColor: "lightgray" }}>
      <div style={styles.container}>
        <form onSubmit={handleSubmit} style={styles.form}>
          <div style={styles.formRow}>
            <div style={styles.formGroup}>
              <label htmlFor="name" style={styles.label}>
                Product Name
              </label>
              <input
                type="text"
                id="name"
                name="name"
                value={product.name}
                onChange={handleInputChange}
                required
                style={styles.input}
              />
            </div>
            <div style={styles.formGroup}>
              <label htmlFor="description" style={styles.label}>
                Product Description
              </label>
              <textarea
                id="description"
                name="description"
                value={product.description}
                onChange={handleInputChange}
                required
                style={{ ...styles.input, height: "100px" }}
              />
            </div>
          </div>

          <div style={styles.formRow}>
            <div style={styles.formGroup}>
              <label htmlFor="category" style={styles.label}>
                Product Category
              </label>
              <input
                type="text"
                id="category"
                name="category"
                value={product.category}
                onChange={handleInputChange}
                required
                style={styles.input}
              />
            </div>
            <div style={styles.formGroup}>
              <label htmlFor="price" style={styles.label}>
                Product Price
              </label>
              <input
                type="number"
                id="price"
                name="price"
                value={product.price}
                onChange={handleInputChange}
                required
                style={styles.input}
              />
            </div>
          </div>

          <div style={styles.formRow}>
            <div style={styles.formGroup}>
              <label htmlFor="company" style={styles.label}>
                Type
              </label>
              <input
                type="text"
                id="company"
                name="company"
                value={product.company}
                onChange={handleInputChange}
                required
                style={styles.input}
              />
            </div>
            <div style={styles.formGroup}>
              <label htmlFor="starRating" style={styles.label}>
                Product Star Rating
              </label>
              <input
                type="number"
                id="starRating"
                name="starRating"
                value={product.starRating}
                onChange={handleInputChange}
                style={styles.input}
              />
            </div>
          </div>

          <div style={styles.formRow}>
            <div style={styles.formGroup}>
              <label htmlFor="stockAvailability" style={styles.label}>
                Stock Availability
              </label>
              <input
                type="number"
                id="stockAvailability"
                name="stockAvailability"
                value={product.stockAvailability}
                onChange={handleInputChange}
                required
                style={styles.input}
              />
            </div>
          </div>

          <div style={styles.formGroup}>
            <label style={styles.checkboxLabel}>
              <input
                type="checkbox"
                name="featured"
                checked={product.featured}
                onChange={handleInputChange}
                style={styles.checkbox}
              />
              Featured
            </label>
          </div>

          <div style={styles.formGroup}>
            <label htmlFor="images" style={styles.label}>
              Upload Product Image
            </label>
            <input
              type="file"
              id="images"
              name="images"
              accept="image/*"
              onChange={handleInputChange}
              style={styles.input}
            />
          </div>

          <button type="submit" style={styles.button}>
            Add Product
          </button>
        </form>
      </div>
    </div>
  );
};

const styles = {
  pageContainer: {
    height: "100vh",
    width: "100vw",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    backgroundColor: "lightblue !important",
  },
  formContainer: {
    width: "100%",
    maxWidth: "700px",
    backgroundColor: "black",
    padding: "30px",
    borderRadius: "10px",
    boxShadow: "0 8px 16px rgba(0, 0, 0, 0.1)",
    margin: "0 20px",
  },
  form: {
    display: "flex",
    flexDirection: "column",
  },
  formRow: {
    display: "flex",
    justifyContent: "space-between",
    marginBottom: "15px",
  },
  formGroup: {
    flex: "1",
    margin: "0 10px",
  },
  label: {
    fontSize: "14px",
    fontWeight: "bold",
    marginBottom: "5px",
    display: "block",
  },
  input: {
    width: "100%",
    padding: "10px",
    borderRadius: "5px",
    border: "1px solid #ccc",
    fontSize: "16px",
  },
  checkboxLabel: {
    display: "flex",
    alignItems: "center",
    fontSize: "14px",
  },
  checkbox: {
    marginRight: "10px",
  },
  button: {
    padding: "12px",
    borderRadius: "5px",
    backgroundColor: "#333",
    color: "#fff",
    border: "none",
    fontSize: "16px",
    cursor: "pointer",
    alignSelf: "flex-start",
    marginTop: "20px",
  },
};

export default AddProducts;
