// import React from "react";
// import { Navigate } from "react-router-dom";
// import { useAuth } from "./context/AuthContext";

// const PrivateRoute = ({ children }) => {
//   const { isLoggedIn, loading } = useAuth();

//   if (loading) {
//     return <div>Loading...</div>;
//   }

//   if (!isLoggedIn) {
//     return <Navigate to="/login" />;
//   }

//   return children;
// };

// export default PrivateRoute;
import React from "react";
import { Navigate } from "react-router-dom";
import { useAuth } from "./context/AuthContext";

const PrivateRoute = ({ children, adminOnly = false }) => {
  const { isLoggedIn, loading, role } = useAuth();
  console.log("🚀 ~ PrivateRoute ~ role:", role);

  if (loading) {
    return <div>Loading...</div>; // Or a loading spinner
  }

  if (!isLoggedIn) {
    // If the route is admin-only and the user is not logged in, redirect to admin login page
    if (adminOnly) {
      return <Navigate to="/adminlogin" />;
    }
    // For non-admin routes, redirect to the general login page
    return <Navigate to="/login" />;
  }

  // If the user is logged in but does not have admin privileges, redirect them to the home page
  if (adminOnly && role !== "admin") {
    return <Navigate to="/" />;
  }

  // If all checks pass, render the children components (protected content)
  return children;
};

export default PrivateRoute;
