import React, { useEffect, useState } from "react";
import emailjs from "emailjs-com";
import axios from "axios";

const Orders = () => {
  const [emailSent, setEmailSent] = useState(false);
  const [orders, setOrders] = useState([]);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    // Fetch orders from the API endpoint
    fetch("/api/get-orders")
      .then((response) => response.json())
      .then((data) => {
        setOrders(data);
        setLoading(false);
      })
      .catch((error) => {
        console.error("Error fetching orders:", error);
        setLoading(false);
      });
  }, []);

  const handleDeleteOrder = async (orderId) => {
    try {
      // Send a DELETE request to the server to delete the order by ID
      await axios.delete(`/api/delete-order/${orderId}`);

      // Update the local state to remove the deleted order
      setOrders((prevOrders) =>
        prevOrders.filter((order) => order._id !== orderId)
      );
    } catch (error) {
      console.error(error);
    }
  };

  const sendEmail = async (toEmail) => {
    emailjs.init("cz9E25FDSiHitH_Gl");

    const templateParams = {
      to_email: toEmail,
      total_amount: 1500,
    };

    try {
      await emailjs.send("service_qqutquh", "template_7dj32fj", templateParams);
      setEmailSent(true);
      alert("Confirmation mail sent successfully!");
    } catch (error) {
      console.error("Error sending email:", error);
    }
  };

  if (loading) {
    return <p>Loading...</p>;
  }

  return (
    <>
      <div style={{ marginLeft: "-300px" }}>
        <div className="col-md-9" id="page-content-wrapper">
          <h2 style={{ marginBottom: "20px", fontFamily: "Arial, sans-serif" }}>Orders</h2>
          {orders.length === 0 ? (
            <p>No orders available.</p>
          ) : (
            <div className="table-responsive">
              <table
                className="table table-bordered table-hover"
                style={{
                  background: "#343a40", // Dark background color for the entire table
                  color: "#fff", // White text for contrast
                  borderCollapse: "collapse",
                  width: "100%",
                }}
              >
                <thead>
                  <tr style={{ background: "#212529", color: "white" }}>
                    <th>Date</th>
                    <th>Name</th>
                    <th>Email</th>
                    <th>Banner/Poster Heading</th>
                    <th>Height (Cm)</th>
                    <th>Width (Cm)</th>
                    <th>City</th>
                    <th>State</th>
                    <th>Address</th>
                    <th>Amount</th>
                    <th>Order Details</th>
                    <th>Paid By</th>
                    <th>Action</th>
                  </tr>
                </thead>
                <tbody>
                  {orders.map((order) => (
                    <tr
                      key={order._id}
                      style={{
                        backgroundColor: "#495057", // Consistent background color for table rows
                        color: "#fff", // White text color
                        borderBottom: "2px solid #ddd",
                        transition: "background-color 0.3s ease",
                      }}
                      onMouseEnter={(e) =>
                        (e.currentTarget.style.backgroundColor = "#6c757d") // Slightly lighter color on hover
                      }
                      onMouseLeave={(e) =>
                        (e.currentTarget.style.backgroundColor = "#495057")
                      }
                    >
                      <td>{order.currentDate}</td>
                      <td>{order.name}</td>
                      <td>{order.email}</td>
                      <td>{order.heading}</td>
                      <td>{order.height}</td>
                      <td>{order.width}</td>
                      <td>{order.city}</td>
                      <td>{order.state}</td>
                      <td>{order.shippingAddress}</td>
                      <td>${order.totalOrderAmount}</td>
                      <td>{order.productDetails}</td>
                      <td>{order.selectedPaymentOption}</td>
                      <td>
                        <button
                          className="btn btn-danger"
                          style={{
                            width: "100%",
                            marginBottom: "10px",
                            padding: "6px 12px",
                          }}
                          onClick={() => handleDeleteOrder(order._id)}
                        >
                          Remove
                        </button>
                        <button
                          className="btn btn-success"
                          style={{
                            width: "100%",
                            padding: "6px 12px",
                          }}
                          onClick={() => sendEmail(order.email)}
                        >
                          Confirm
                        </button>
                      </td>
                    </tr>
                  ))}
                </tbody>
              </table>
            </div>
          )}
        </div>
      </div>
    </>
  );
};

export default Orders;
