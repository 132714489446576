// @ts-nocheck
import React, { createContext, useContext, useState, useEffect } from "react";
import {
  isAuthenticated,
  getToken,
  login,
  logout,
  getUserRole,
  adminLogin,
} from "./auth";
import axios from "axios";

// Create the AuthContext
const AuthContext = createContext();

// Custom hook to use the AuthContext
export const useAuth = () => useContext(AuthContext);

// AuthProvider component
export const AuthProvider = ({ children }) => {
  const [isLoggedIn, setIsLoggedIn] = useState(false);
  const [loading, setLoading] = useState(true);
  const [role, setRole] = useState(null);

  useEffect(() => {
    const checkAuth = async () => {
      const authenticated = isAuthenticated();
      setIsLoggedIn(authenticated);
      setRole(getUserRole());
      setLoading(false);
      console.log("first role is authenticated", getUserRole());
    };

    checkAuth();
  }, []);

  const signIn = async (username, password) => {
    const success = await login(username, password);
    if (success) {
      setIsLoggedIn(true);
      setRole(getUserRole());
    }
    return success;
  };
  const adminSignIn = async (username, password) => {
    const success = await adminLogin(username, password);
    if (success) {
      setIsLoggedIn(true);
      setRole(getUserRole());
    }
    return success;
  };
  const signOut = () => {
    logout();
    setIsLoggedIn(false);
    setRole(null);
  };

  // Axios interceptor to attach the token to headers
  axios.interceptors.request.use(
    (config) => {
      const token = getToken();
      if (token) {
        config.headers.Authorization = `Bearer ${token}`;
      }
      return config;
    },
    (error) => Promise.reject(error)
  );

  const value = {
    isLoggedIn,
    loading,
    role,
    adminSignIn,
    signIn,
    signOut,
  };

  return <AuthContext.Provider value={value}>{children}</AuthContext.Provider>;
};
