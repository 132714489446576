// @ts-nocheck
import React, { useContext, useState } from "react";
import { useNavigate } from "react-router-dom";
import { Container, Form, Button } from "react-bootstrap";
import { MdAccountCircle } from "react-icons/md"; // Import the React icon
import "../src/styles/losin.css";
import { NavLink } from "react-router-dom";
import { useAuth } from "./context/AuthContext";
import Footer from "./components/newHome/Footer/Footer";

function Login() {
  const navigate = useNavigate();
  const { signIn, isLoggedIn } = useAuth(); // Use the correct hook

  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [message, setMessage] = useState("");
  const [loading, setLoading] = useState(false);

  const loginUser = async (e) => {
    e.preventDefault();

    try {
      const success = await signIn(email, password);

      if (success) {
        setMessage("Login successful!");
        window.alert("Login successful!");
        navigate("/profile");
      } else {
        setMessage("Login failed. Please check your credentials.");
      }
    } catch (error) {
      console.error("Error:", error);
      setMessage("An error occurred during login.");
    } finally {
      setLoading(false);
    }
  };

  return (
    <div style={{ background: "#001F3F" }}>
      <div className="container login-container">
        <h2 className="text-center">Login</h2>
        <div className="text-center user-icon">
          <MdAccountCircle size={100} color="black" />{" "}
          {/* Use MdAccountCircle from react-icons */}
        </div>
        <Form onSubmit={loginUser}>
          <Form.Group>
            <Form.Label>Email:</Form.Label>
            <Form.Control
              type="email"
              id="email"
              value={email}
              required
              onChange={(e) => setEmail(e.target.value)}
              placeholder="Enter email"
            />
          </Form.Group>
          <Form.Group>
            <Form.Label>Password:</Form.Label>
            <Form.Control
              type="password"
              value={password}
              onChange={(e) => setPassword(e.target.value)}
              required
              placeholder="Enter password"
            />
          </Form.Group>
          <br />
          <Button variant="dark" type="submit" block>
            Login
          </Button>
          <br />
          <br />
          <NavLink to="/register" style={{ color: "black" }}>
            Create Account
          </NavLink>
        </Form>
      </div>
      <br />
      <br />
      <Footer />
    </div>
  );
}

export default Login;
