// @ts-nocheck
import React from "react";
import { useProductContext } from "../context/ProductContext";
import { NavLink } from "react-router-dom";
import Product from "./Product";
import GridView from "./GridView";

const Feature = () => {
  const { isLoading, featureProducts } = useProductContext();
  console.log(featureProducts);
  if (isLoading) {
    return <div>......Loading</div>;
  }

  return (
    <>
      <h5
        style={{
          textAlign: "center",
          color: "white",
          fontSize: "30px",
          fontWeight: "bold",
        }}
      >
        Feature Products
      </h5>
      <br />

      <GridView products={featureProducts}  />

      <br />
      <br />
      <br />
      <NavLink to="/products" style={{ textDecoration: "none" }}>
        {" "}
        <p style={{ textAlign: "center", fontWeight: "bold", color: "gray" }}>
          View All Products
        </p>
      </NavLink>
    </>
  );
};
export default Feature;
