// src/auth.js
import axios from "axios";

const API_URL = "https://test-node-flame.vercel.app/api"; // Your backend URL

export const login = async (email, password) => {
  try {
    const response = await axios.post(`${API_URL}/signin`, {
      email,
      password,
    });
    const token = response.data.token;
    if (token) {
      localStorage.setItem("authToken", token);
      localStorage.setItem("userRole", "user");
      return true;
    }
  } catch (error) {
    console.error("Login failed:", error);
    return false;
  }
};
export const adminLogin = async (username, password) => {
  try {
    const response = await fetch(
      "https://test-node-flame.vercel.app/api/adminlogin",
      {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({ username, password }),
      }
    );
    if (response.ok) {
      // Admin login successful
      localStorage.setItem("userRole", "admin");
      alert("Admin login successful");
      return true;

      // You can navigate to the admin dashboard or perform other actions here
    } else {
      // Admin login failed
      alert("Invalid credentials");
      return false;
    }
  } catch (error) {
    console.error("Error:", error);
    return false;
  }
};
export const logout = () => {
  localStorage.removeItem("userRole");
  localStorage.removeItem("authToken");
  return;
};

export const getToken = () => {
  return localStorage.getItem("authToken");
};
export const getUserRole = () => {
  return localStorage.getItem("userRole");
};

export const isAuthenticated = () => {
  const token = getToken();
  return !!token || getUserRole() === "admin"; // Admins don't have a token
};

export const isAdmin = () => {
  const role = getUserRole();
  return role === "admin";
};

export const isUser = () => {
  const role = getUserRole();
  return role === "user";
};
