// @ts-nocheck
import React from "react";

const Card = ({ title, description }) => {
  return (
    <div className="p-4 bg-light rounded-3 w-100">
      {/* You can add an image relevant to the category here */}
      {/* <img src="/path-to-image.png" alt={title} /> */}
      <h1 className="h4 font-weight-medium my-3">{title}</h1>
      <p className="mb-3 lead">
        {description}
      </p>
      <div className="text-black border-bottom border-black d-inline-block">
        Read More{" "}
        <span className="bg-black px-2 font-weight-bold rounded-circle text-white">
          +
        </span>
      </div>
    </div>
  );
};

export default Card;
