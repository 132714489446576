// @ts-nocheck
import React from "react";
import Product from "./Product";

const GridView = ({ products }) => {
  return (
    <div className="container text-center">
      <div className="row row-cols-1 row-cols-sm-1 row-cols-md-2 row-cols-lg-3 row-cols-xl-4 g-4 m-auto">
        {products.map((curElem) => (
          <div key={curElem.id}>
            <Product {...curElem} />
          </div>
        ))}
      </div>
    </div>
  );
};

export default GridView;
