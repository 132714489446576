// @ts-nocheck
import React from "react";
import Service from "./components/newHome/Services/Service";
import Trusted from "./components/trusted";
import Footer from "./components/newHome/Footer/Footer";
import Feature from "./components/feature";
import Category from "./components/newHome/Browse/Category";
import MediumCarousel from "./Caresol";
const Home = () => {
  const data = {
    name: "Ostore",
  };
  return (
    <div style={{ background: "#001F3F" }}>
      <br />
      <MediumCarousel />
      <br />
      <Feature />
      <br />
      <br />
      <Category />
      <Service />
      {/*  <Partner /> */}
      <Footer />
    </div>
  );
};
export default Home;
