// @ts-nocheck
import React, { useRef } from "react";
import Slider from "react-slick";
import { AiOutlineLeft, AiOutlineRight } from "react-icons/ai";
import "../src/styles/EcommerceCarousel.css"; // Assuming you're using an external CSS file

const EcommerceCarousel = () => {
  const sliderRef = useRef(null); // Reference for the slider

  const services = [
    "/images/image1.jpg",
    "/images/image2.jpg",
    "/images/image3.jpg",
    "/images/image4.jpg",
    "/images/image6.jpg",
    "/images/image7.jpg",
    "/images/image8.jpg",
    "/images/image9.jpg",
    "/images/image0.jpg",
    "/images/image11.jpg",
  ];

  const settings = {
    dots: true,
    infinite: true,
    speed: 500,
    autoplay: false,
    slidesToShow: 1,
    slidesToScroll: 1,
    arrows: false, // Disable default arrows
  };

  const handlePrevClick = () => {
    sliderRef.current.slickPrev(); // Manually trigger previous slide
  };

  const handleNextClick = () => {
    sliderRef.current.slickNext(); // Manually trigger next slide
  };

  return (
    <div className="carousel-container" style={{ position: "relative" }}>
      <Slider {...settings} ref={sliderRef}>
        {services.map((service, index) => (
          <div key={index} style={{ position: "relative" }}>
            <div
              className="custom-arrow-left"
              onClick={handlePrevClick}
              style={{
                position: "absolute",
                top: "50%",
                background: "red",
                left: "20px",
                transform: "translateY(-50%)",
                cursor: "pointer",
                zIndex: 999999,
              }}
            >
              <AiOutlineLeft size={30} color="#fff" />
            </div>
            <img
              src={service}
              alt={service}
              style={{
                width: "100%",
                height: "700px",
                objectFit: "cover",
                borderRadius: "10px", // Optional: Add border radius if needed
              }}
            />
            {/* Left Arrow */}

            {/* Right Arrow */}
            <div
              className="custom-arrow-right"
              onClick={handleNextClick}
              style={{
                position: "absolute",
                top: "50%",
                backgroundColor: "red",
                right: "20px",
                transform: "translateY(-50%)",
                cursor: "pointer",
                zIndex: 999,
              }}
            >
              <AiOutlineRight size={30} color="#fff" />
            </div>
          </div>
        ))}
      </Slider>
    </div>
  );
};

export default EcommerceCarousel;
