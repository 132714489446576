// @ts-nocheck
import React from "react";
import Card from "./Card";

const Category = () => {
  const categories = [
    {
      title: "Custom Banners",
      description: "High-quality banners for your business, events, or personal use.",
    },
    {
      title: "Custom Posters",
      description: "Design eye-catching posters to promote your brand or event.",
    },
    {
      title: "Event Banners",
      description: "Banners tailored to your event needs, delivered at your doorstep.",
    },
    {
      title: "Promotional Posters",
      description: "Engage your audience with vibrant and custom promotional posters.",
    },
    {
      title: "Outdoor Banners",
      description: "Durable outdoor banners for any occasion or advertising needs.",
    },
    {
      title: "Retail Posters",
      description: "Attract customers with striking posters for your retail space.",
    },
    {
      title: "Business Banners",
      description: "Create professional banners to enhance your business visibility.",
    },
    {
      title: "Personalized Designs",
      description: "Get custom designs tailored to your specific preferences.",
    },
  ];

  return (
    <div className="container my-5 border-1">
      <div className="d-flex justify-content-between align-items-center my-4">
        <div>
          <h1 className="h5 text-white fw-bold ps-3 mb-3">Category</h1>
          <h1 className="h1 fw-bold text-white">Browse By Category</h1>
        </div>
      </div>
      <div className="row g-3">
        {categories.map((category, index) => (
          <div className="col-12 col-sm-6 col-lg-3" key={index}>
            <Card title={category.title} description={category.description} />
          </div>
        ))}
      </div>
    </div>
  );
};

export default Category;
