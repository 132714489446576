// @ts-nocheck
import React from "react";
import ServiceSlider from "./ServiceSlider";
import CustomSlider from "./CustomSlider";
import MobileSlider from "./MobileSlider";

const Service = () => {
  const services = [
    {
      title: "Customized Banners",
      image: "/images/image1.jpg",
      buttonText: "Learn More",
    },
    {
      title: "Custom Posters",
      image: "/images/image0.jpg",
      buttonText: "Learn More",
    },
    {
      title: "Event Banners",
      image: "/images/image6.jpg",
      buttonText: "Learn More",
    },
    {
      title: "Promotional Posters",
      image: "/images/Sale.jpg",
      buttonText: "Learn More",
    },
  ];

  return (
    <div className="bg-light text-black py-5 my-3">
      <div className="container">
        <div className="row align-items-center">
          <div className="col-md-6 mb-4 mb-md-0">
            <div className="mb-3">
              <h1 className="h5 border-start border-white ps-3">Our Services</h1>
            </div>
            <h2 className="h1 font-weight-bold mb-4">Most Popular Services</h2>
            <p className="text-black-50 mb-4">
              We specialize in delivering high-quality customized banners and posters directly to your doorstep. Whether you're looking to promote your business, enhance your event, or add a personal touch to any occasion, our custom designs will make it stand out.
            </p>
            <button className="btn btn-black text-white px-4 py-2 rounded-pill font-weight-semibold">
              Explore More
            </button>
          </div>
          <div className="col-md-6">
            {/* <CustomSlider slides={services} /> */}
            <MobileSlider slides={services} />
          </div>
        </div>
      </div>
    </div>
  );
};

export default Service;
