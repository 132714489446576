import React from "react";
import CheckoutForm from "./stripe/index";

const Checkout = () => {
  return (
    <div style={{ background: "#001F3F" }}>
      <CheckoutForm />
    </div>
  );
};
export default Checkout;
