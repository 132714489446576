import React from 'react';
import Maps from './components/maps';
import Footer from "./components/newHome/Footer/Footer";

const Contact = () => {
  return (
    <>
    
    <Maps />
    <Footer />
    </>
  )
}
export default Contact;
