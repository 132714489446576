// @ts-nocheck
// import React from "react";
// import { useFilterContext } from "../context/FilterContext";

// const Filtersection = () => {
//   const {
//     filters: { text, category, company },
//     updateFilterValue,
//     All_Products,
//     clearfilter,
//   } = useFilterContext();

//   const getUniqueData = (data, property) => {
//     let newVal = data.map((curElem) => {
//       return curElem[property];
//     });
//     newVal = ["ALL", ...new Set(newVal)];
//     return newVal;
//   };

//   const categoryOnlyData = getUniqueData(All_Products, "category");
//   const CompanyOnlyData = getUniqueData(All_Products, "company");

//   return (
//     <div className="container bg-light px-4 border" style={{ padding: "10px" }}>
//       <div className="row">
//         <div className="col-md-3 overflow-hidden">
//           <form onSubmit={(e) => e.preventDefault()}>
//             <label style={{ color: "black", fontWeight: "bold" }}>Search</label>
//             <br />
//             <input
//               type="text"
//               name="text"
//               value={text}
//               onChange={updateFilterValue}
//               style={{ border: "1px solid gray" }}
//             />
//           </form>
//         </div>
//         <div className="col-md-3">
//           <form action="#">
//             <label style={{ fontWeight: "bold" }}>Category</label>
//             <br />
//             <select
//               name="category"
//               id="category"
//               value={category}
//               onChange={updateFilterValue}
//             >
//               {categoryOnlyData.map((curElem, index) => (
//                 <option key={index} value={curElem}>
//                   {curElem}
//                 </option>
//               ))}
//             </select>
//           </form>
//         </div>
//         <div className="col-md-3">
//           <form action="#">
//             <label style={{ fontWeight: "bold" }}>Select Company</label>
//             <br />
//             <select
//               name="company"
//               id="company"
//               value={company}
//               onChange={updateFilterValue}
//             >
//               {CompanyOnlyData.map((curElem, index) => (
//                 <option key={index} value={curElem}>
//                   {curElem}
//                 </option>
//               ))}
//             </select>
//           </form>
//         </div>
//         <div className="col-md-3" style={{ marginTop: "10px" }}>
//           <button
//             className="btn btn"
//             onClick={clearfilter}
//             style={{ backgroundColor: "black", color: "white" }}
//           >
//             Clear Filter
//           </button>
//         </div>
//       </div>
//     </div>
//   );
// };

// export default Filtersection;
import React from "react";
import { useFilterContext } from "../context/FilterContext";

const Filtersection = () => {
  const {
    filters, // Add this to get the 'filters' object
    filters: { text, category, company }, updateFilterValue,
    All_Products,
    clearfilter,
  } = useFilterContext();

  const getUniqueData = (data, property) => {
    let newVal = data.map((curElem) => curElem[property]);
    return [...new Set(newVal)];
  };

  const categoryOnlyData = getUniqueData(All_Products, "category");
  const CompanyOnlyData = getUniqueData(All_Products, "company");

  const handleCheckboxChange = (e) => {
    const { name, value, checked } = e.target;
    updateFilterValue({
      target: {
        name,
        value: checked ? [...filters[name], value] : filters[name].filter((item) => item !== value),
      },
    });
  };

  return (
    <div className="filter-section bg-light p-3 border mt-4">
      <form onSubmit={(e) => e.preventDefault()}>
        {/* Search */}
        <div className="form-group mb-3">
          <label style={{ fontWeight: "bold" }}>Search</label>
          <input
            type="text"
            name="text"
            value={text}
            onChange={updateFilterValue}
            className="form-control"
            placeholder="Search product"
          />
        </div>

        {/* Category (Checkboxes) */}
        <div className="form-group mb-3">
          <label style={{ fontWeight: "bold" }}>Category</label>
          {categoryOnlyData.map((curElem, index) => (
            <div key={index} className="form-check">
              <input
                type="checkbox"
                name="category"
                value={curElem}
                checked={category.includes(curElem)}
                onChange={handleCheckboxChange}
                className="form-check-input"
                id={`category-${curElem}`}
              />
              <label
                className="form-check-label"
                htmlFor={`category-${curElem}`}
              >
                {curElem}
              </label>
            </div>
          ))}
        </div>

        {/* Company (Checkboxes) */}
        <div className="form-group mb-3">
          <label style={{ fontWeight: "bold" }}>Company</label>
          {CompanyOnlyData.map((curElem, index) => (
            <div key={index} className="form-check">
              <input
                type="checkbox"
                name="company"
                value={curElem}
                checked={company.includes(curElem)}
                onChange={handleCheckboxChange}
                className="form-check-input"
                id={`company-${curElem}`}
              />
              <label
                className="form-check-label"
                htmlFor={`company-${curElem}`}
              >
                {curElem}
              </label>
            </div>
          ))}
        </div>

        {/* Clear Filters */}
        <div className="form-group">
          <button className="btn btn-dark w-100" onClick={clearfilter}>
            Clear Filter
          </button>
        </div>
      </form>
    </div>
  );
};

export default Filtersection;

