// @ts-nocheck
// import React, { useState } from "react";
// import { useNavigate } from "react-router-dom";
// import { NavLink } from "react-router-dom";
// import { FaShoppingCart, FaUser, FaBars, FaTimes } from "react-icons/fa";
// import "../styles/Nav.css";
// import { useAuth } from "../context/AuthContext";

// const NavBar = () => {
//   const history = useNavigate();
//   const { isLoggedIn, signOut } = useAuth();
//   const [isDrawerOpen, setIsDrawerOpen] = useState(false);

//   const handleLoginButtonClick = () => {
//     if (isLoggedIn) {
//       signOut();
//     } else {
//       history("/login");
//     }
//   };

//   const handleUserIconClick = () => {
//     if (!isLoggedIn) {
//       alert("Please login first.");
//     }
//   };

//   const toggleDrawer = () => {
//     setIsDrawerOpen(!isDrawerOpen);
//   };

//   const closeDrawer = () => {
//     setIsDrawerOpen(false);
//   };

//   return (
//     <nav className="navbar navbar-expand-lg">
//       <div className="container">
//         <NavLink to="/" style={{ textDecoration: "none" }}>
//           <a className="navbar-brand" href="#">
//             wowDisplay
//           </a>
//         </NavLink>
//         <div className="d-lg-none">
//           {/* Mobile menu button */}
//           <FaBars
//             onClick={toggleDrawer}
//             className="mobile-menu-button text-white"
//           />
//         </div>
//         <div className="d-none d-lg-flex">
//           <ul className="navbar-nav">
//             <li className="nav-item">
//               <NavLink to="/" style={{ textDecoration: "none" }}>
//                 <a className="nav-link" href="#">
//                   Home
//                 </a>
//               </NavLink>
//             </li>
//             <li className="nav-item">
//               <NavLink to="/products" style={{ textDecoration: "none" }}>
//                 <a className="nav-link" href="#">
//                   Products
//                 </a>
//               </NavLink>
//             </li>
//             <li className="nav-item">
//               <NavLink to="/contact" style={{ textDecoration: "none" }}>
//                 <a className="nav-link" href="#">
//                   Contact-Us
//                 </a>
//               </NavLink>
//             </li>
//             <li className="nav-item">
//               <NavLink to="/about" style={{ textDecoration: "none" }}>
//                 <a className="nav-link" href="#">
//                   About-Us
//                 </a>
//               </NavLink>
//             </li>
//           </ul>
//           <div className="navbar-icons">
//             <span className="icon-space"></span>
//             <NavLink to="/profile" style={{ color: "black" }}>
//               <i>
//                 {isLoggedIn && (
//                   <FaUser
//                     onClick={handleUserIconClick}
//                     className="text-white"
//                   />
//                 )}
//               </i>
//             </NavLink>
//             <span className="icon-space"></span>
//             <NavLink to="/cart" style={{ color: "black" }}>
//               <i>{isLoggedIn && <FaShoppingCart className="text-white" />}</i>
//             </NavLink>
//             <span className="icon-space"></span>
//             <button onClick={handleLoginButtonClick} className="btn btn-dark">
//               {isLoggedIn ? "Logout" : "Login"}
//             </button>
//           </div>
//         </div>

//         {/* Drawer for mobile view */}
//         {isDrawerOpen && (
//           <div className="drawer d-lg-none " style={{ background: "#001F3F" }}>
//             <div className="drawer-header">
//               <FaTimes
//                 onClick={closeDrawer}
//                 className="drawer-close-button text-white"
//               />
//             </div>
//             <ul className="drawer-nav">
//               <li className="nav-item">
//                 <NavLink
//                   to="/"
//                   style={{ textDecoration: "none" }}
//                   onClick={closeDrawer}
//                 >
//                   <a className="nav-link" href="#">
//                     Home
//                   </a>
//                 </NavLink>
//               </li>
//               <li className="nav-item">
//                 <NavLink
//                   to="/products"
//                   style={{ textDecoration: "none" }}
//                   onClick={closeDrawer}
//                 >
//                   <a className="nav-link" href="#">
//                     Products
//                   </a>
//                 </NavLink>
//               </li>
//               <li className="nav-item">
//                 <NavLink
//                   to="/contact"
//                   style={{ textDecoration: "none" }}
//                   onClick={closeDrawer}
//                 >
//                   <a className="nav-link" href="#">
//                     Contact-Us
//                   </a>
//                 </NavLink>
//               </li>
//               <li className="nav-item">
//                 <NavLink
//                   to="/about"
//                   style={{ textDecoration: "none" }}
//                   onClick={closeDrawer}
//                 >
//                   <a className="nav-link" href="#">
//                     About-Us
//                   </a>
//                 </NavLink>
//               </li>
//             </ul>
//             <div className="drawer-icons">
//               <NavLink
//                 to="/profile"
//                 style={{ color: "black" }}
//                 onClick={closeDrawer}
//               >
//                 <i>
//                   {isLoggedIn && (
//                     <FaUser
//                       onClick={handleUserIconClick}
//                       className="text-white"
//                     />
//                   )}
//                 </i>
//               </NavLink>
//               <span className="icon-space"></span>
//               <NavLink
//                 to="/cart"
//                 style={{ color: "black" }}
//                 onClick={closeDrawer}
//               >
//                 <i>{isLoggedIn && <FaShoppingCart className="text-white" />}</i>
//               </NavLink>
//               <span className="icon-space"></span>
//               <button
//                 onClick={() => {
//                   handleLoginButtonClick();
//                   closeDrawer();
//                 }}
//                 className="btn btn-dark"
//               >
//                 {isLoggedIn ? "Logout" : "Login"}
//               </button>
//             </div>
//           </div>
//         )}
//       </div>
//     </nav>
//   );
// };

// export default NavBar;
import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import { NavLink } from "react-router-dom";
import { FaShoppingCart, FaUser, FaBars, FaTimes } from "react-icons/fa";
import "../styles/Nav.css";
import { useAuth } from "../context/AuthContext";

const NavBar = () => {
  const history = useNavigate();
  const { isLoggedIn, signOut } = useAuth();
  const [isDrawerOpen, setIsDrawerOpen] = useState(false);

  const handleLoginButtonClick = () => {
    if (isLoggedIn) {
      signOut();
    } else {
      history("/login");
    }
  };

  const handleUserIconClick = () => {
    if (!isLoggedIn) {
      alert("Please login first.");
    }
  };

  const toggleDrawer = () => {
    setIsDrawerOpen(!isDrawerOpen);
  };

  const closeDrawer = () => {
    setIsDrawerOpen(false);
  };

  return (
    <nav className="navbar navbar-expand-lg">
      <div className="container d-flex justify-content-between align-items-center">
        {/* Logo (Left Corner) */}
        <NavLink to="/" style={{ textDecoration: "none" }}>
  <a
    className="navbar-brand"
    href="#"
    style={{
      fontFamily: "Poppins, sans-serif",
      fontSize: "24px",
      fontWeight: "bold",
      color: "#16757D", // Primary color
      display: "inline-block",
      padding: "8px 15px",
      transition: "color 0.3s ease, background-color 0.3s ease", // Smooth transition
    }}
    onMouseEnter={(e) => {
      e.currentTarget.style.color = "#fff"; // Change text color to white on hover
      e.currentTarget.style.backgroundColor = "#16757D"; // Add background color on hover
    }}
    onMouseLeave={(e) => {
      e.currentTarget.style.color = "#16757D"; // Revert to original text color
      e.currentTarget.style.backgroundColor = "transparent"; // Remove background color on hover out
    }}
  >
    wowDisplay
  </a>
</NavLink>


        {/* Center Nav Links */}
        <div className="d-none d-lg-block">
          <ul className="navbar-nav d-flex justify-content-center">
            <li className="nav-item mx-3">
              <NavLink to="/" style={{ textDecoration: "none" }}>
                <a className="nav-link" href="#">
                  Home
                </a>
              </NavLink>
            </li>
            <li className="nav-item mx-3">
              <NavLink to="/products" style={{ textDecoration: "none" }}>
                <a className="nav-link" href="#">
                  Products
                </a>
              </NavLink>
            </li>
            <li className="nav-item mx-3">
              <NavLink to="/contact" style={{ textDecoration: "none" }}>
                <a className="nav-link" href="#">
                  Contact-Us
                </a>
              </NavLink>
            </li>
            <li className="nav-item mx-3">
              <NavLink to="/about" style={{ textDecoration: "none" }}>
                <a className="nav-link" href="#">
                  About-Us
                </a>
              </NavLink>
            </li>
          </ul>
        </div>

        {/* Right Corner Icons and Logout/Login */}
        <div className="d-flex align-items-center">
          <div className="navbar-icons d-none d-lg-flex">
            <NavLink to="/profile" style={{ color: "black" }}>
              <i>
                {isLoggedIn && (
                  <FaUser
                    onClick={handleUserIconClick}
                    className="text-white"
                  />
                )}
              </i>
            </NavLink>
            <span className="icon-space mx-2"></span>
            <NavLink to="/cart" style={{ color: "black" }}>
              <i>{isLoggedIn && <FaShoppingCart className="text-white" />}</i>
            </NavLink>
            <span className="icon-space mx-2"></span>
          </div>

          <button
            onClick={handleLoginButtonClick}
            className="btn btn-dark ml-3 d-none d-lg-flex"
          >
            {isLoggedIn ? "Logout" : "Login"}
          </button>
        </div>

        {/* Mobile Menu Button */}
        <div className="d-lg-none">
          <FaBars
            onClick={toggleDrawer}
            className="mobile-menu-button text-white"
          />
        </div>

        {/* Drawer for mobile view */}
        {isDrawerOpen && (
          <div className="drawer d-lg-none" style={{ background: "#001F3F" }}>
            <div className="drawer-header">
              <FaTimes
                onClick={closeDrawer}
                className="drawer-close-button text-white"
              />
            </div>
            <ul className="drawer-nav">
              <li className="nav-item">
                <NavLink
                  to="/"
                  style={{ textDecoration: "none" }}
                  onClick={closeDrawer}
                >
                  <a className="nav-link" href="#">
                    Home
                  </a>
                </NavLink>
              </li>
              <li className="nav-item">
                <NavLink
                  to="/products"
                  style={{ textDecoration: "none" }}
                  onClick={closeDrawer}
                >
                  <a className="nav-link" href="#">
                    Products
                  </a>
                </NavLink>
              </li>
              <li className="nav-item">
                <NavLink
                  to="/contact"
                  style={{ textDecoration: "none" }}
                  onClick={closeDrawer}
                >
                  <a className="nav-link" href="#">
                    Contact-Us
                  </a>
                </NavLink>
              </li>
              <li className="nav-item">
                <NavLink
                  to="/about"
                  style={{ textDecoration: "none" }}
                  onClick={closeDrawer}
                >
                  <a className="nav-link" href="#">
                    About-Us
                  </a>
                </NavLink>
              </li>
            </ul>
            <div className="drawer-icons">
              <NavLink
                to="/profile"
                style={{ color: "black" }}
                onClick={closeDrawer}
              >
                <i>
                  {isLoggedIn && (
                    <FaUser
                      onClick={handleUserIconClick}
                      className="text-white"
                    />
                  )}
                </i>
              </NavLink>
              <span className="icon-space"></span>
              <NavLink
                to="/cart"
                style={{ color: "black" }}
                onClick={closeDrawer}
              >
                <i>{isLoggedIn && <FaShoppingCart className="text-white" />}</i>
              </NavLink>
              <span className="icon-space"></span>
              <button
                onClick={() => {
                  handleLoginButtonClick();
                  closeDrawer();
                }}
                className="btn btn-dark"
              >
                {isLoggedIn ? "Logout" : "Login"}
              </button>
            </div>
          </div>
        )}
      </div>
    </nav>
  );
};

export default NavBar;
