import React from "react";

const Success = () => {
  return (
    <div style={{ background: "#001F3F", color: "white" }}>
      <h1>Payment Success!</h1>
    </div>
  );
};
export default Success;
