import React from "react";
import Footer from "./components/newHome/Footer/Footer";
import Header from "./components/Header";
import { useProductContext } from "./context/ProductContext";

export const About = () => {
  const { myname } = useProductContext();
  const data = {
    name: "PrintBanners",
  };

  return (
    <>
      {myname}
      <Header mydata={data} />
      <br />
      <Footer />
    </>
  );
};

export default About;
